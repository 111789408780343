.carousels {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-top: 2rem !important;
}

.seo-title {
	font: 500 1.375rem / 1.091 var(--font-family-main) !important;
	margin-bottom: 1rem;
}
.seo-description {
	font: 400 1rem / 1.25 var(--font-family-main) !important;
}

@media (min-width: 1025px) {
	.carousels {
		margin-top: 4rem !important;
		gap: 3rem;
	}

	.seo-title {
		font: 500 2rem / 1.5 var(--font-family-main) !important;
	}

	.seo-description {
		font: 400 1.125rem / 1.333 var(--font-family-main) !important;
	}
}

.hiddenTitle {
	position: absolute;
	left: -9999px;
	height: 0;
	overflow: hidden;
}
