.fadein {
	min-height: 100px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.fadein.visible {
	min-height: 0px;
	opacity: 1;
}

.loadingCarousel {
	min-height: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-black);
}

[data-theme='dark'] .loadingCarousel {
	color: var(--color-white);
}
