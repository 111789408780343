.iframeBlockSection {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0 auto 0 auto;
}

.iframeBlockSection:first-of-type {
	margin-top: 1.5rem;
}
