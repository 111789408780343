[data-round_corners='true'] .card-placeholder-wrapper {
	border-radius: 0.5rem;
}

[data-round_corners='false'] .card-placeholder-wrapper {
	border-radius: 0;
}

.card-placeholder-wrapper {
	display: flex;
	min-width: 10.5rem;
	min-height: 5rem;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 1px dashed var(--color-gray-400);
	gap: 0.5rem;
	padding: 1rem;
	overflow: hidden;
}

.card-placeholder-wrapper > p {
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 1.875rem;
	color: var(--color-gray-400);
	text-align: center;
}

@media (max-width: 1024px) {
	.card-placeholder-wrapper {
		padding: 0.5rem;
	}

	.card-placeholder-wrapper > p {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.card-placeholder-wrapper svg {
		width: 2.5rem;
		height: 2.5rem;
	}

	.small_card {
		width: 10.5rem;
		height: 5rem;
	}

	.medium_card {
		width: 11rem;
		height: 6rem;
	}
}

@media (min-width: 1025px) {
	.small_card {
		width: 18rem;
		height: 9.5rem;
	}

	.medium_card {
		width: 18rem;
		height: 12rem;
	}
}
